import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import { useTranslation } from 'react-i18next'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ScrollAnimation from "react-animate-on-scroll"
import AliceCarousel from "react-alice-carousel"
import 'react-alice-carousel/lib/alice-carousel.css'

import styles from "../styles/modules/item.module.scss"

export const query = graphql`
  query itemQuery($lang: String!, $uid: String!) {
    prismic {
      item(lang: $lang, uid: $uid) {
        description
        dimensions
        material
        price
        sold
        title
        images {
          image
          imageSharp {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        _meta {
          lang
          uid
          alternateLanguages {
            uid
            lang
          }
        }
      }
    }
  }
`

const Item = ({ data }) => {
  const { t } = useTranslation('item');
  const item = data.prismic.item
  if (!item) return null

  // Title animation
  const wordArray = RichText.asText(item.title).split(' ')
  const fadeDelay = 500
  const fadeLetterDelay = 50

  let fadeIndex = 0

  return (
    <Layout>
      <SEO
        title={RichText.asText(item.title)}
        description={item.description ? RichText.asText(item.description) : ''}
        lang={item._meta.lang}
      />

      <div className={styles.item}>
        <div className={styles.item__image}>
          <div className={styles.image}>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              duration={1.5}
              offset={0}
            >
              {!item.images?.length ? '' :
                item.images.length > 1 ?
                  <AliceCarousel
                    infinite={false}
                    duration={500}
                    buttonsDisabled={true}
                    autoHeight={true}
                    items={
                      item.images.map((image, index) => (
                        <Img
                          key={`image-${index}`}
                          className={styles.img}
                          fluid={image.imageSharp?.childImageSharp?.fluid}
                          alt={RichText.asText(item.title)}
                        />
                      ))
                    }

                  /> :
                  <Img
                    className={styles.img}
                    fluid={item?.images[0]?.imageSharp?.childImageSharp?.fluid}
                    alt={RichText.asText(item.title)}
                  />
              }
            </ScrollAnimation>
          </div>
        </div>

        <div className={styles.item__content}>
          <div className={styles.content}>
            <div className={styles.titleWrapper}>
              <h1 className={styles.title}>
                  {wordArray.map((word, index) => {
                  const wordArray = word.split('')

                  return (
                      <div key={`fade-word-${index}`} className={styles.title__word}>
                      {wordArray.map((char) => (
                        <ScrollAnimation
                          key={`fade-title-${++fadeIndex}`}
                          animateIn="fadeInUp"
                          animateOnce={true}
                          delay={fadeIndex * fadeLetterDelay + fadeDelay}
                          duration={1.2}
                          offset={0}
                        >
                          {char.replace(' ', `\u00A0`)}
                        </ScrollAnimation>
                      ))}
                    </div>
                  )
                })}
              </h1>

              <ScrollAnimation
                animateIn="fadeIn"
                animateOnce={true}
                delay={500}
                duration={1.8}
                offset={0}
              >
                <p className={styles.price}>
                  {item.price.toLocaleString(item._meta.lang, { style: 'currency', currency: 'CAD', currencyDisplay: 'code' }).replace('.00', '').replace(',00', '')}
                </p>
              </ScrollAnimation>
            </div>

            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              delay={800}
              duration={1.8}
              offset={0}
            >
              {item.description ? <p>{RichText.asText(item.description)}</p> : ''}

              <table className={styles.properties}>
                <tbody>
                  <tr className={styles.property}>
                    <td className={styles.property__name}>{t('material')}</td>
                    <td className={styles.property__value}>{RichText.asText(item.material)}</td>
                  </tr>
                  <tr className={styles.property}>
                    <td className={styles.property__name}>{t('dimensions')}</td>
                    <td className={styles.property__value}>{RichText.asText(item.dimensions)}</td>
                  </tr>
                </tbody>
              </table>
            </ScrollAnimation>

            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              delay={1100}
              duration={1.8}
              offset={0}
            >
              <a className="btn"
                href={`mailto:contact@hoolaloom.com?Subject=${RichText.asText(item.title)}&Body=${
                  encodeURIComponent(
                    t('body').replace('[url]', `https://hoolaloom.com/${item._meta.lang === 'fr-ca' ? 'fr-ca/' : ''}pieces/${item._meta.uid}`)
                  ).replace(/%3Cbr%3E/g, '%0D%0A')
                }`}
              >
                {t('contact')}
              </a>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Item
